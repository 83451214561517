<template>
  <v-row justify="center" v-if="shower === true">
    <v-dialog v-model="shower" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-avatar :color="color.theme" :dark="color.darkTheme" size="36" class="mr-4">
            <v-icon :dark="color.darkTheme">mdi-folder-swap</v-icon> </v-avatar
          ><b>{{ $t("multimovefiles.move") }}</b></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-layout row justify-center wrap class="mt-6">
            <v-flex lg12>
              <v-layout row wrap class="elevation-0" justify-center fill-height>
                <!-- selectfolder -->
                <v-flex lg5>
                  <v-card-text>
                    <b>{{ $t("multimovefiles.selectfile") }} </b>
                  </v-card-text>
                  <v-list nav dense flat>
                    <v-list-item v-for="(item, i) in filemultipledownload" :key="i">
                      <v-list-item-icon>
                        <v-icon :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content two-line>
                        <v-list-item-title>
                          <b> {{ item.file_name }} </b>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.file_permission_2 === '04' || item.file_permission_2 === '05' || item.file_permission_2 === '06'">
                          <v-chip label small color="primary" outlined>
                            <b>{{ $t("multimovefiles.permission_granted") }}</b>
                          </v-chip></v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-else
                          ><v-chip label small color="error" outlined>
                            <b>{{ $t("multimovefiles.permission_denied") }}</b>
                          </v-chip></v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-progress-circular
                        :size="20"
                        v-if="item.status_response === 'WR'"
                        indeterminate
                        :color="warning"
                      ></v-progress-circular>
                      <v-list-item-icon v-else>
                        <v-icon :color="item.status_response === 'OK' ? 'green' : 'red'">{{
                          item.status_response === "OK" ? "check" : item.status_response === "ER" ? "clear" : ""
                        }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex lg1 class="text-center">
                  
                  <v-divider vertical></v-divider>
                </v-flex>

                <!-- targetmove -->
                <!-- <v-flex lg5 v-if="createprogress === false">
                  <v-layout row wrap align-center justify-center>
                    <v-flex lg2 class="text-center">
                      <v-btn
                        :disabled="enableback"
                        @click="querydirectories(parentdirectory)"
                        x-small
                        fab
                        depressed
                        class="ml-4"
                        :color="color.theme"
                      >
                        <v-icon :color="color.chipText">keyboard_backspace</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex lg10 class="text-left">
                      <v-card-text
                        ><b>{{ $t("multimovefiles.moveto") }}</b>
                        {{ parentname }}
                      </v-card-text>
                    </v-flex>
                  </v-layout>
                  <v-list nav dense class="elevation-0" v-if="listdirectory.length > 0">
                    <v-list-item-group v-model="targetfolder" @change="clicker">
                      <v-list-item
                        v-for="(item, i) in listdirectory"
                        :key="i"
                        @dblclick="querydirectories(item.folder_id)"
                        :disabled="disablemove"
                      >
                        <v-list-item-icon>
                           สี folder
                          <v-icon :color="item.folder_status == 'M' ? 'green' : 'blue'">folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.folder_name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-subheader>
                      <v-chip class="ma-2" :color="color.theme" text-color="white">
                        <v-avatar left>
                          <v-icon>mdi-folder-move</v-icon>
                        </v-avatar>
                        {{ parentname }}
                      </v-chip>
                    </v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{ $t("movefiles.nofolder") }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex lg5 v-else>
                  <v-layout justify-center row wrap fill-height align-center>
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex> -->
                <v-flex lg5>
                  <v-tabs v-model="tab" outlined>
                    <v-tab
                      @click="
                        (checktab = 1), querydirectories(parentfolder, parentfolder), item = ''
                      "
                      :disabled="createprogress"
                      >{{dataAccountActive.type !== 'Citizen' ? $t("toolbar.mydrive_bu") : $t("toolbar.mydrive")}}</v-tab
                    >
                    <v-tab
                      @click="(checktab = 2), fn_getlist()"
                      v-if="dataAccountActive.type === 'Citizen'"
                      :disabled="createprogress"
                      >{{$t("toolbar.mydrive_bu")}}</v-tab
                    >
                    <v-tab-item>
                      <v-layout row wrap class="pa-4">
                        <v-flex v-if="createprogress === false">
                          <v-list nav dense v-if="listdirectory.length > 0">
                            <v-layout row wrap>
                              <v-flex lg12 sm12 xs10 class="mt-1">
                                <v-chip :color="color.theme" text-color="white">
                                  <v-btn
                                    :disabled="enableback"
                                    @click="querydirectories(parentdirectory)"
                                    style="cursor: pointer; background-color: transparent !important;"
                                    icon
                                    color="white"
                                    class="ml-n1 mr-n2"
                                  >
                                    <v-icon>mdi-arrow-left-circle</v-icon>
                                  </v-btn>
                                  <span class="ml-4" v-if="resolutionScreen >= 400">
                                    {{ parentname }}</span
                                  >
                                  <span class="ml-4" v-else>{{
                                    parentname.length >= 30
                                      ? parentname.substring(0, 30) + "..."
                                      : parentname
                                  }}</span>
                                </v-chip>
                              </v-flex>
                            </v-layout>
                            <br />
                            <v-list-item-group v-model="item" :color="color.theme" @change="clicker">
                              <v-list-item
                                v-for="(item, i) in listdirectory"
                                :key="i"   
                                @dblclick="querydirectories(item.folder_id)"
                                :disabled="fn_permission_folder(item.permission_account_v2)"
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      item.folder_status === 'M' ? 'green' : '#ffc107'
                                    "
                                    large
                                    :disabled="fn_permission_folder(item.permission_account_v2)"
                                    >folder</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    item.folder_name
                                  }}</v-list-item-title>
                                </v-list-item-content>
                                <!-- Add new Permission chip  -->
                                <v-list-item-action
                                  v-if="
                                    fn_permission_folder(item.permission_account_v2)
                                  "
                                >
                                  <v-chip x-small class="py-2">
                                    {{ $t("movefiles.permission_denied") }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                          <v-list nav dense v-else>
                            <v-layout row wrap>
                              <v-flex lg12 sm12 xs10 class="mt-1">
                                <v-chip :color="color.theme" text-color="white">
                                  <v-btn
                                    :disabled="enableback"
                                    @click="querydirectories(parentdirectory)"
                                    style="cursor: pointer; background-color: transparent !important;"
                                    icon
                                    color="white"
                                    class="ml-n1 mr-n2"
                                  >
                                    <v-icon>mdi-arrow-left-circle</v-icon>
                                  </v-btn>
                                  <span class="ml-4"> {{ parentname }}</span>
                                </v-chip>
                              </v-flex>
                            </v-layout>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="text-center">{{
                                  $t("movefiles.nofolder")
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                        <v-flex v-else>
                          <v-layout justify-center>
                            <br />
                            <v-progress-circular
                              class="mt-6 mb-6 pt-6 pb-6"
                              :size="50"
                              :dark="color.darkTheme"
                              :color="color.theme"
                              indeterminate
                            ></v-progress-circular>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-tab-item>
                    <v-tab-item v-if="dataAccountActive.type === 'Citizen'">
                      <v-layout row wrap class="pa-4">
                        <v-flex v-if="createprogress === false">
                          <v-list nav dense v-if="listbusiness.length > 0">
                            <v-layout row wrap>
                              <v-flex lg12 sm12 xs10 class="mt-1">
                                <v-chip :color="color.theme" text-color="white">
                                  <v-btn
                                    :disabled="enableback"
                                    @click="
                                      searchchildfolder_back(
                                        backto_folder,
                                        account_active_inbiz
                                      )
                                    "
                                    style="cursor: pointer; background-color: transparent !important;"
                                    icon
                                    color="white"
                                    class="mr-n3 ml-n2"
                                  >
                                    <v-icon>mdi-arrow-left-circle</v-icon>
                                  </v-btn>
                                  <span class="ml-4" v-if="resolutionScreen >= 400">{{
                                    parentname_biz
                                  }}</span>
                                  <span class="ml-4" v-else>{{
                                    parentname_biz.length >= 30
                                      ? parentname_biz.substring(0, 30) + "..."
                                      : parentname_biz
                                  }}</span>
                                </v-chip>
                              </v-flex>
                            </v-layout>
                            <br />
                            <v-list-item-group v-model="item_" :color="color.theme" @change="clickerBusiness">
                              <v-list-item
                                v-for="(item_, i) in listbusiness"
                                :key="i"
                                :disabled="fn_permission_folder(item_.permission_account_v2)"
                                @dblclick="
                                  querydirectories(
                                    item_.folder_id === '' ||
                                      item_.folder_id === '' ||
                                      item_.folder_id === undefined
                                      ? this.moveto
                                      : item_.folder_id,
                                    item_
                                  ),
                                  item_ = '',
                                  status_get_business = false
                                "
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      item_.folder_status === 'M' ? 'green' : '#ffc107'
                                    "
                                    large
                                    :disabled="fn_permission_folder(item_.permission_account_v2)"
                                    >folder</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    item_.folder_name
                                  }}</v-list-item-title>
                                </v-list-item-content>
                                <!-- Add new Permission chip  -->
                                <v-list-item-action
                                  v-if="
                                    fn_permission_folder(item_.permission_account_v2)
                                  "
                                >
                                  <v-chip x-small class="py-2">
                                    {{ $t("movefiles.permission_denied") }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                          <v-list nav dense v-else>
                            <v-layout row wrap>
                              <v-flex lg12 sm12 xs10 class="mt-1">
                                <v-chip :color="color.theme" text-color="white">
                                  <v-btn
                                    :disabled="enableback"
                                    @click="
                                      searchchildfolder_back(
                                        backto_folder,
                                        account_active_inbiz
                                      )
                                    "
                                    style="cursor: pointer; background-color: transparent !important;"
                                    icon
                                    color="white"
                                    class="mr-n3 ml-n2"
                                  >
                                    <v-icon>mdi-arrow-left-circle</v-icon>
                                  </v-btn>
                                  <span class="ml-4"> {{ parentname_biz }}</span>
                                </v-chip>
                              </v-flex>
                            </v-layout>

                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="text-center">{{
                                  $t("movefiles.nofolder")
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                        <v-flex v-else>
                          <v-layout justify-center>
                            <br />
                            <v-progress-circular
                              class="mt-6 mb-6 pt-6 pb-6"
                              :size="50"
                              :dark="color.darkTheme"
                              :color="color.theme"
                              indeterminate
                            ></v-progress-circular>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-tab-item>
                  </v-tabs>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- <v-card-text class="text-center" v-else>
          <br />
          <v-progress-circular
            class="mt-6 mb-6 pt-6 pb-6"
            :size="50"
            :dark="color.darkTheme"
            :color="color.theme"
            indeterminate
          ></v-progress-circular>
        </v-card-text> -->
        <v-divider></v-divider>
        <!-- {{ parentdirectory }}
        {{ typeof(targetfolder)}} -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            :disabled="createprogress"
            @click="submitmove === true ? ($emit('closedialog'), $emit('closeappsbar'), $emit('reload'), cleardata()) : ($emit('closedialog'), cleardata())"
            >{{ $t("movefiles.buttonclose") }}</v-btn
          >
          <!-- @click="$emit('closedialog'), cleardata(), $emit('closeappsbar'), $emit('reload')" -->
          <v-btn
            class="white--text"
            :color="color.theme"
            :loading="createprogress"
            @click="check_summitmove()"
            :disabled="disablemove"
            >{{ wordingbutton }}</v-btn
          >
           <!-- :loading="false"
          @click="movefile()"
          :disabled="disablemove || length_all === length_denied" -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {},
  props: ["show", "filemultipledownload", "parentfolder"],
  data() {
    return {
      disablemove: false,
      enableback: false,
      createprogress: false,
      listdirectory: [],
      parentdirectory: "",
      parentname: "", 
      parentname_biz: "",
      targetfolder: "",
      moveto: "",
      wordingbutton: this.$t("movefiles.maveat"),
      tab: 0,
      checktab: 1,
      listbusiness: [],
      item: "",
      item_: "",
      backto_folder: "",
      account_active_inbiz: "",
      first_opendialog: true,
      business_name: "",
      submitmove: false,
      status_get_business: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.filemultipledownload.length >= 1 && this.show == true) {
          console.log("multimovefile", this.filemultipledownload);
          // this.querydirectories(this.parentfolder);
          if (this.first_opendialog === true) {
            this.first_opendialog = false;
            if (this.checktab === 1) {
              this.querydirectories(this.parentfolder, this.parentfolder);
            } else {
              this.fn_getlist();
            }
          }     
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.first_opendialog = true;
          console.log("!val");
          this.$emit("closedialog");
        }
      },
    },
    length_denied: {
      get() {
        // จำนวนไฟล์ที่ไม่มีสิทธิ์ย้าย
        return this.filemultipledownload.filter((item) => {
          return item.file_permission_2 === "01" || item.file_permission_2 === "02" || item.file_permission_2 === "03";
        }).length;
      },
    },
    length_all: {
      get() {
        return this.filemultipledownload.length;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    async movefile() {
      console.log("multimovefile", this.filemultipledownload);
      let datalist = [];
      for (let index = 0; index < this.filemultipledownload.length; index++) {
        const element = this.filemultipledownload[index];
        if (element.file_status !== "M" && (element.file_permission_2 === "04" || element.file_permission_2 === "05" || element.file_permission_2 === "06")) {
          datalist.push({
            data_id: element.file_id,
            data_type: element.file_type,
          });
        } else {
          element.status_response = "ER";
        }
      }
      // console.log(datalist);

      let targetfolderid = "";
      if (
         // this.targetfolder === undefined ||
        // this.targetfolder === null ||
        // this.targetfolder === "" ||
        // typeof this.targetfolder === Number
        this.item === undefined ||
        this.item === null ||
        this.item === "" ||
        typeof this.item === Number
      ) {
        // ไม่เลือกโฟลเดอร์
        targetfolderid = this.moveto;
      } else {
        // เลือกโฟลเดอร์
        // targetfolderid = this.listdirectory[this.targetfolder]["folder_id"];
        targetfolderid = this.listdirectory[this.item]["folder_id"];
      }
      console.log("target folder", targetfolderid);
      let adminstatus = "False";
      if (this.role_level == "True") {
        adminstatus = "True";
      } else {
        adminstatus = "False";
      }
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        data_list: datalist,
        target_folder_id: targetfolderid,
        current_folder_id: this.parentfolder,
        admin_status: adminstatus,
      };
      console.log("payload", payload);

      this.createprogress = true;
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/multiple_move_data", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          this.createprogress = false;
          console.log(response.data);
          if (response.data.status === "OK") {
            this.disablemove = true;
            for (let index = 0; index < response.data.result.length; index++) {
              const element = response.data.result[index];
              let findID = this.filemultipledownload.findIndex((a) => a.file_id === element.id);
              if (findID === -1) {
                // ไม่เจอ
                continue;
              } else {
                // เจอ
                this.filemultipledownload[findID].status_response = element.status;
              }
            }
            console.log("file select response", this.filemultipledownload);
            this.submitmove = true;
            // this.$emit("closedialog");
            // this.$emit("reload");
            // this.$emit("closeappsbar");
            Toast.fire({
              icon: "success",
              title: this.$t("movefiles.multisuccess"),
              // title: this.$t("movefiles.movefiles") + this.$t("movefiles.success"),
              // title: "ย้ายไฟล์ " + this.filedata.file_name + " สำเร็จ"
            });
          } else if (response.data.errorCode === "ER405") {
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.mutifilefoldernomove"),
            });
          } else {
            console.log("else", response);
            for (let index = 0; index < this.filemultipledownload.length; index++) {
              const element = this.filemultipledownload[index];
              element.status_response = "ER";
            }
            this.disablemove = true;
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.muticannotmove"),
              // title: this.$t("movefiles.filenomove"),
              // title: this.$t('movefiles.movefiles') +" "+this.filedata.file_name +" "+this.$t('movefiles.nosuccess')
              // title: "ย้ายไฟล์" + this.filedata.file_name + " ไม่สำเร็จ"
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          console.log("dialog", error);
          Toast.fire({
            icon: "error",
            title: this.$t("movefiles.cannotmovefile"),
          });
        });
    },
    
    async summitmovefile2() {
      this.createprogress = true;
      let datalist = [];
      let status_response = "";
      for (let index = 0; index < this.filemultipledownload.length; index++) {
        if (this.filemultipledownload[index].file_status !== "M" && (this.filemultipledownload[index].file_permission_2 === "04" || this.filemultipledownload[index].file_permission_2 === "05" || this.filemultipledownload[index].file_permission_2 === "06")) {
          datalist.push({
            data_id: this.filemultipledownload[index].file_id,
            data_type: this.filemultipledownload[index].file_type,
          });
          this.filemultipledownload[index].status_response = "WR";
        } else {
          this.filemultipledownload[index].status_response = "ER";
        }
      }
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        var payload;
        if (
          this.item_ === undefined ||
          this.item_ === null ||
          this.item_ === ""
        ) {
          if (this.filemultipledownload[i].file_type === "folder") {
            payload = {
              data_id: this.filemultipledownload[i].file_id,
              data_type: "folder",
              account_id: this.dataAccountId,
              folder_id_move_to: this.moveto,
              user_id: this.dataUsername,
            };
            status_response = await this.querymovefolder2(payload);
          } else {
            payload = {
              data_id: this.filemultipledownload[i].file_id,
              data_type: "file",
              account_id: this.dataAccountId,
              folder_id_move_to: this.moveto,
              user_id: this.dataUsername,
            };
            status_response = await this.querymovefile2(payload);
          }
        } else {
          if (this.filemultipledownload[i].file_type === "folder") {
            console.log(3);
            payload = {
              data_id: this.filemultipledownload[i].file_id,
              data_type: "folder",
              account_id: this.dataAccountId,
              folder_id_move_to: this.listbusiness[this.item_] !== undefined ? this.listbusiness[this.item_]["folder_id"] : this.moveto,
              user_id: this.dataUsername,
            };
            status_response = await this.querymovefolder2(payload);
          } else {
            console.log(4);
            payload = {
              data_id: this.filemultipledownload[i].file_id,
              data_type: "file",
              account_id: this.dataAccountId,
              folder_id_move_to: this.listbusiness[this.item_] !== undefined ? this.listbusiness[this.item_]["folder_id"] : this.moveto,
              user_id: this.dataUsername,
            };
            status_response = await this.querymovefile2(payload);
          }
        }
        this.filemultipledownload[i].status_response = status_response;
      }
      this.submitmove = true;
      let check_er_status_response = this.filemultipledownload.filter(item => item.status_response === "ER");
      if (check_er_status_response.length > 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("movefiles.multicannotmovesomefilefolder"),
        });
      } else {
        Toast.fire({
          icon: "success",
          title: this.$t("movefiles.multisuccess"),
        });
      }
      this.createprogress = false;
      this.disablemove = true;
    },

    async querymovefile2(payload) {
      let auth = await gbfGenerate.generateToken();
      let status_response = "";
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/move/data_citizen_to_business",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            status_response = "OK"
          } else if (response.data.errorCode === "ER500") {
            status_response = "ER"
          } else {
            status_response = "ER"
          }
        })
        .catch((error) => {
          status_response = "ER"
          console.log(error);
        });
      return status_response;
    },

    async querymovefolder2(payload) {
      let auth = await gbfGenerate.generateToken();
      let status_response = ""
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/move/data_citizen_to_business",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            status_response = "OK"
          } else if (response.data.errorCode === "ER500") {
            status_response = "ER";
          } else {
            status_response = "ER";
          }
        })
        .catch((error) => {
          status_response = "ER"
          console.log(error);
        });
      return status_response;
    },

    clicker() {
      // if (this.targetfolder === undefined) {
      //   this.wordingbutton = this.$t("movefiles.maveat");
      // } else {
      //   this.wordingbutton = this.$t("movefiles.move");
      // }
      if (this.item === undefined || this.item === "") {
        this.wordingbutton = this.$t("movefiles.maveat");
      } else {
        this.wordingbutton = this.$t("movefiles.move");
      }
    },
    
    clickerBusiness() {
      if (this.item_ === undefined || this.item_ === "") {
        this.wordingbutton = this.$t("movefiles.maveat");
        if (this.status_get_business === true) {
          this.disablemove = true;
        }
      } else {
        this.wordingbutton = this.$t("movefiles.move");
        this.disablemove = false;
      }
    },

    cleardata() {
      this.disablemove = false;
      this.createprogress = false;
      this.listdirectory = [];
      this.parentdirectory = "";
      this.parentname = "";
      this.moveto = "";
      this.targetfolder = "";
      this.listbusiness = [];
      this.tab = 0;
      this.checktab = 1;
      this.item = "";
      this.item_ = "";
      this.backto_folder = "";
      this.account_active_inbiz = "";
      this.first_opendialog = true;
      this.business_name = "";
      this.submitmove = false;
    },

    //Add new permission check
    fn_permission_folder(permission_text) {
      if (permission_text !== undefined) {
        let permission_number = permission_text.split("")[1];
        if (permission_number < 3) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    check_summitmove() {
      if (this.checktab === 1) {
        console.log("เข้า summit 1");
        this.movefile();
      } else {
        console.log("เข้า summit 2");
        this.summitmovefile2();
      }
    },

    async fn_getlist() {
      this.createprogress = true;
      this.disablemove = true;
      this.status_get_business = true;
      this.enableback = true;
      this.listbusiness = [];
      let payload = {
        user_id: this.dataUsername,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_biz_for_move_data",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.result.length; i++) {
              let datalistbusines = {};
              datalistbusines["account_id"] =
                response.data.result[i]["account_id"];
              datalistbusines["business_id"] =
                response.data.result[i]["business_id"];
              datalistbusines["first_name_eng"] =
                response.data.result[i]["first_name_eng"];
              datalistbusines["folder_name"] =
                response.data.result[i]["first_name_th"];
              datalistbusines["folder_id"] =
                response.data.result[i]["my_folder_id"];
              this.listbusiness.push(datalistbusines);
            }
            this.parentname_biz = this.$t("admin.business");
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
          }
          this.item_ = "";
          this.clickerBusiness();
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },

    querydirectories(parameter, item) {
      this.createprogress = true;
      this.enableback = false;
      this.disablemove = false;
      console.log("parameter1", parameter);
      // this.createprogress = false;
      console.log("parameter", parameter);
      // setTimeout(() => {
      //   this.searchchildfolder_new(parameter);
      // }, 500);
      setTimeout(() => {
        if (this.checktab === 1) {
          console.log("เข้า1");
          this.searchchildfolder_new(parameter);
        } else {
          console.log("เข้า2");
          if (this.parentname_biz === this.$t("admin.business")) {
            this.business_name = item.folder_name;
          }
          this.searchchildfolder_biz(parameter, item);
        }
      }, 500);

      this.disablemove = this.length_denied === this.length_all ? true : false;
    },

    async searchchildfolder_new(parameter2) {
      let payload = {
        user_id: this.dataUsername,
        folder_id: parameter2,
        account_id: this.dataAccountId,
        status_folder: "",
      };
      let auth = await gbfGenerate.generateToken();
      let apiMoveFolder = this.dataAccountActive.type === 'Citizen' ? '/api/v2/search_folder_byid' : '/api/v2/search_folder_byid_admin_ignore' // แต่ละเส้นจะเช็คสิทธิ์ไม่เหมืนอกัน
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FOLDER + apiMoveFolder, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          this.listdirectory = [];
          this.targetfolder = "";
          if (response.data.status === "OK") {
            // this.targetfolder = Folder ID ที่ต้องการย้าย

            //กรณีที่ F
            if (response.data.your_folder[0]["folder_status"] === "R" && this.dataAccountActive["type"] === "Business") {
              //เข้ามาใน Business Drives
              if (parameter2 === this.dataAccountActive["business_info"]["my_folder_id"]) {
                for (let a = 0; a < this.dataAccountActive["directory_business"].length; a++) {
                  let datafolder = {};
                  datafolder["folder_id"] = this.dataAccountActive["directory_business"][a]["department_folder_id"];
                  datafolder["folder_name"] = this.dataAccountActive["directory_business"][a]["department_name"];
                  datafolder["folder_status"] = "M";
                  datafolder["parent_folder_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
                  datafolder["name_eng"] = this.dataAccountActive["business_info"]["first_name_eng"];
                  datafolder["name_th"] = this.dataAccountActive["business_info"]["first_name_th"];
                  datafolder["user_id"] = "";
                  this.listdirectory.push(datafolder);
                }
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.moveto = this.dataAccountActive["business_info"]["my_folder_id"];
                // this.enableback = false;
                this.enableback = true;
                this.parentname = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );

                console.log(this.listdirectory);

                //อยุ่ใน Department Folder
              } else if (
                this.dataAccountActive["directory_business"].findIndex((t) => t.department_folder_id === parameter2) >= 0
              ) {
                let index_data = this.dataAccountActive["directory_business"].findIndex(
                  (t) => t.department_folder_id === parameter2
                );
                console.log(index_data);
                this.moveto = this.dataAccountActive["directory_business"][index_data]["department_folder_id"];
                this.parentdirectory = this.dataAccountActive["business_info"]["my_folder_id"];
                this.enableback = false;
                this.parentname = this.dataAccountActive["directory_business"][index_data]["department_name"];

                //อยุ่ใน Root Business
              } else {
                let datafolder = {};
                datafolder["folder_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
                datafolder["folder_name"] = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );
                datafolder["folder_status"] = "M";
                datafolder["parent_folder_id"] = this.dataAccountActive["directory_id"];
                datafolder["name_eng"] = this.dataAccountActive["business_info"]["first_name_eng"];
                datafolder["name_th"] = this.dataAccountActive["business_info"]["first_name_th"];
                datafolder["user_id"] = "";
                this.listdirectory.push(datafolder);

                this.moveto = this.dataAccountActive["directory_id"];
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.enableback = true;
                this.parentname = this.$t("toolbar.mydrive");
              }
            } else if (response.data.your_folder[0]["folder_status"] === "R" && this.dataAccountActive["type"] === "Citizen") {
              this.moveto = this.dataAccountActive["directory_id"];
              this.parentdirectory = this.dataAccountActive["directory_id"];
              this.enableback = true;
              this.parentname = this.$t("toolbar.mydrive");

              // this.parentname = this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"],this.$t("default"));
            } else {
              this.moveto = response.data.your_folder[0]["folder_id"];
              this.parentdirectory =
                response.data.your_folder[0]["parent_folder_id"] === null
                  ? this.dataAccountActive["type"] === "Business"
                    ? this.dataAccountActive["business_info"]["my_folder_id"]
                    : this.dataAccountActive["directory_id"]
                  : response.data.your_folder[0]["parent_folder_id"];
              this.enableback = false;
              // ! folder_name
              this.parentname = response.data.your_folder[0]["folder_name"];
            }

            // multi folder
            // เช็คกับ filemultidownload เอาตัวซ้ำออก
            let selectfolder = [];
            for (let index = 0; index < this.filemultipledownload.length; index++) {
              const element = this.filemultipledownload[index];
              if (element.file_type === "folder") {
                selectfolder.push(element.file_id);
              }
            }

            let i;
            for (i = 0; i < response.data.folder.length; i++) {
              // console.log('res',response.data.folder[i])
              let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
              if (index === -1) {
                console.log("index if myfile", index);
                if(response.data.folder[i]['folder_status'] === 'M'){
                  continue
                }
                this.listdirectory.push(response.data.folder[i]);
              } else {
                continue;
                console.log("index else", index);
              }
            }
            this.item = "";
            this.clicker();
            this.createprogress = false;
          } else {
            this.listdirectory = [];
            this.createprogress = false;
            console.log("");
          }
        })
        .catch((err) => {
          this.listdirectory = [];
          this.createprogress = false;
          console.log(err);
        });
    },

    async searchchildfolder(parameter2) {
      console.log("filemulti", this.filemultipledownload);
      let payload;
      payload = {
        user_id: this.dataUsername,
        folder_id: parameter2,
        account_id: this.dataAccountId,
        status_folder: "",
      };
      let auth = await gbfGenerate.generateToken();
      // console.log("Payload", payload);
      var response = await this.axios.post(process.env.VUE_APP_SERVICE_SELECT_FOLDER + "/api/v2/search_folder_byid", payload, {
        headers: { Authorization: auth.code },
      });
      // console.log("Response dialog multimove", response);
      try {
        this.targetfolder = "";
        this.listdirectory = [];
        if (response.data.status === "OK") {
          this.createprogress = false;
          console.log("query response", response);
          if (this.dataAccountActive["type"] === "Citizen") {
            if (response.data.your_folder[0]["folder_status"] === "R") {
              this.enableback = true;
            } else {
              this.enableback = false;
            }

            // multi folder
            // เช็คกับ filemultidownload เอาตัวซ้ำออก
            let selectfolder = [];
            for (let index = 0; index < this.filemultipledownload.length; index++) {
              const element = this.filemultipledownload[index];
              if (element.file_type === "folder") {
                selectfolder.push(element.file_id);
              }
            }

            let i;
            for (i = 0; i < response.data.folder.length; i++) {
              // console.log('res',response.data.folder[i])
              let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
              if (index === -1) {
                console.log("index if myfile", index);
                this.listdirectory.push(response.data.folder[i]);
              } else {
                continue;
                console.log("index else", index);
              }
            }

            this.parentdirectory = response.data.your_folder[0]["parent_folder_id"];
            // ! folder_name
            this.parentname = response.data.your_folder[0]["folder_name"];
            this.moveto = response.data.your_folder[0]["folder_id"];
          } else {
            // ------------------------------------------------------- Business --------------------------------------------------------------
            if (response.data.your_folder[0]["folder_status"] === "R") {
              let checkbusinessfolder = this.dataAccountActive["directory_business"].findIndex(
                (t) => t.department_folder_id === parameter2
              );
              // console.log(checkbusinessfolder);
              if (checkbusinessfolder === -1) {
                //แสดงว่าอยู่ในหน้า root business
                console.log("PARAMETER: ", parameter2);
                console.log("MY FOLDER ID: ", this.dataAccountActive["business_info"]["my_folder_id"]);
                if (this.dataAccountActive["business_info"]["my_folder_id"] === parameter2) {
                  console.log("1");
                  this.enableback = false;
                  // เอาโฟลเดอร์ที่ select ออก
                  let selectfolder = [];
                  console.log("filemulti", this.filemultipledownload);
                  for (let i = 0; i < this.filemultipledownload.length; i++) {
                    const element = this.filemultipledownload[i];
                    if (element.file_type === "folder" && element.file_status !== "M") {
                      selectfolder.push(element.file_id);
                    }
                  }
                  // console.log("SELECT :", selectfolder);
                  // department
                  let a = 0;
                  for (a = 0; a < this.dataAccountActive["directory_business"].length; a++) {
                    let datafolder = {};
                    datafolder["folder_id"] = this.dataAccountActive["directory_business"][a]["department_folder_id"];
                    datafolder["folder_name"] = this.dataAccountActive["directory_business"][a]["department_name"];
                    datafolder["folder_status"] = "M";
                    datafolder["parent_folder_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
                    datafolder["name_eng"] = this.dataAccountActive["business_info"]["first_name_eng"];
                    datafolder["name_th"] = this.dataAccountActive["business_info"]["first_name_th"];
                    datafolder["user_id"] = "";
                    this.listdirectory.push(datafolder);
                  }

                  let i, j;
                  for (i = 0; i < response.data.folder.length; i++) {
                    let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
                    if (index === -1) {
                      console.log("index if", index);
                      this.listdirectory.push(response.data.folder[i]);
                    } else {
                      continue;
                      console.log("index else", index);
                    }
                  }
                  console.log("LIST:", this.listdirectory);
                  this.parentdirectory = this.dataAccountActive["directory_id"];
                  this.parentname = this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  );
                  if (this.targetfolder === undefined || this.targetfolder === null || this.targetfolder === "") {
                    this.moveto = this.dataAccountActive["business_info"]["my_folder_id"];
                  } else {
                    this.moveto = this.dataAccountActive["directory_id"];
                  }
                  // console.log('moveto',this.moveto)
                  // console.log('root business',this.dataAccountActive["business_info"]["my_folder_id"])
                } else if (this.dataAccountActive["directory_id"] === response.data.your_folder[0]["folder_id"]) {
                  console.log("2");
                  // หน้า myfile
                  // folderที่อยู่ข้างนอก department
                  this.enableback = true;
                  let datafolder = {};
                  datafolder["folder_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
                  datafolder["folder_name"] = this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  );
                  datafolder["folder_status"] = "M";
                  datafolder["parent_folder_id"] = this.dataAccountActive["directory_id"];
                  datafolder["name_eng"] = this.dataAccountActive["business_info"]["first_name_eng"];
                  datafolder["name_th"] = this.dataAccountActive["business_info"]["first_name_th"];
                  datafolder["user_id"] = "";
                  this.listdirectory.push(datafolder);

                  // เอา department ออกจาก select
                  let selectfolder = [];
                  for (let i = 0; i < this.filemultipledownload.length; i++) {
                    const element = this.filemultipledownload[i];
                    if (element.file_type === "folder" && element.file_status !== "M") {
                      selectfolder.push(element.file_id);
                    }
                  }
                  // ถ้าfilemultipledonwload.length === response.data.folder.length ให้ listdirectory เป็น []
                  let i, j;
                  for (i = 0; i < response.data.folder.length; i++) {
                    let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
                    if (index === -1) {
                      console.log("index if", index);
                      this.listdirectory.push(response.data.folder[i]);
                    } else {
                      continue;
                      console.log("index else", index);
                    }
                  }
                  // if (selectfolder.length == response.data.folder.length) {
                  //   this.listdirectory = [];
                  //   this.listdirectory.push(datafolder);
                  // }

                  this.parentdirectory = this.dataAccountActive["directory_id"];
                  // this.parentname = this.$store.getters.dataTypeusercurrent(this.$store.state.account_active['type'],this.$t('default'))
                  this.moveto = this.dataAccountActive["directory_id"];

                  if (this.$t("default") === "en") {
                    this.parentname = "My Drive";
                  } else {
                    this.parentname = "ไดรฟ์ของฉัน";
                  }
                } else {
                  console.log("3");
                  this.enableback = false;
                  this.parentdirectory = response.data.your_folder[0]["parent_folder_id"];
                  // ! folder_name
                  this.parentname = response.data.your_folder[0]["folder_name"];
                  this.moveto = response.data.your_folder[0]["folder_id"];
                }
              } else {
                //แสดงว่าอยู่ในหน้า folder department
                console.log("folder department");
                this.enableback = false;
                // let datafolder = {};
                // datafolder["folder_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
                // datafolder["folder_name"] = this.$store.getters.dataTypeusercurrent(
                //   this.$store.state.account_active["type"],
                //   this.$t("default")
                // );
                // datafolder["folder_status"] = "M";
                // datafolder["parent_folder_id"] = this.dataAccountActive["directory_id"];
                // datafolder["name_eng"] = this.dataAccountActive["business_info"]["first_name_eng"];
                // datafolder["name_th"] = this.dataAccountActive["business_info"]["first_name_th"];
                // datafolder["user_id"] = "";
                // this.listdirectory.push(datafolder);
                // this.listdirectory = response.data.folder;
                // let b = 0;
                // for (b = 0; b < response.data.folder.length; b++) {
                //   if (
                //     this.filemultipledownload["file_id"] === response.data.folder[b]["folder_id"] &&
                //     this.filemultipledownload["file_type"] === "folder"
                //   ) {
                //     continue;
                //   } else {
                //     this.listdirectory.push(response.data.folder[b]);
                //   }
                // }
                let selectfolder = [];
                for (let i = 0; i < this.filemultipledownload.length; i++) {
                  const element = this.filemultipledownload[i];
                  if (element.file_type === "folder" && element.file_status !== "M") {
                    selectfolder.push(element.file_id);
                  }
                }

                let i, j;
                for (i = 0; i < response.data.folder.length; i++) {
                  let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
                  if (index === -1) {
                    console.log("index if", index);
                    this.listdirectory.push(response.data.folder[i]);
                  } else {
                    continue;
                    console.log("index else", index);
                  }
                }
                this.parentdirectory = this.dataAccountActive["directory_id"];
                // ! folder_name
                this.parentname = response.data.your_folder[0]["folder_name"];
                this.moveto = response.data.your_folder[0]["folder_id"];
              }
            } else {
              // select folder เป็น business
              // directory business
              console.log("out department");
              // เช็คกับ filemultidownload เอาตัวซ้ำออก
              let selectfolder = [];
              for (let index = 0; index < this.filemultipledownload.length; index++) {
                const element = this.filemultipledownload[index];
                if (element.file_type === "folder") {
                  selectfolder.push(element.file_id);
                }
              }

              let i, j;
              for (i = 0; i < response.data.folder.length; i++) {
                let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
                if (index === -1) {
                  console.log("index if", index);
                  this.listdirectory.push(response.data.folder[i]);
                } else {
                  continue;
                  console.log("index else", index);
                }
              }
              // if (selectfolder.length == response.data.folder.length) {
              //   this.listdirectory = [];
              // }

              // if (selectfolder.length == response.data.folder.length) {
              //   this.listdirectory = [];
              // }

              // this.listdirectory = response.data.folder;
              // this.parentdirectory = response.data.your_folder[0]["parent_folder_id"];
              // this.parentname = response.data.your_folder[0]["folder_name"];
              this.parentdirectory = this.dataAccountActive["directory_id"];
              this.parentname = this.$store.getters.dataTypeusercurrent(
                this.$store.state.account_active["type"],
                this.$t("default")
              );
              this.moveto = response.data.your_folder[0]["folder_id"];
              console.log("moveto out", this.moveto);
            }
          }
        } else {
          this.listdirectory = [];
          this.createprogress = false;
        }
      } catch (ex) {
        this.createprogress = false;
        console.log(ex);
      }
      console.log("listend", this.listdirectory);
    },

    async searchchildfolder_biz(parameter2, item2) {
      this.listbusiness = [];
      this.targetfolder = "";
      this.full_path = "";
      let payload = {
        user_id: this.dataUsername,
        folder_id: parameter2,
        account_id: item2.account_id,
        status_folder: "",
      };
      let auth = await gbfGenerate.generateToken();
      let apiMoveFolder = this.dataAccountActive.type === 'Citizen' ? 
      this.checktab === 2 ? '/api/v2/search_folder_byid_admin_ignore' : '/api/v2/search_folder_byid' : '/api/v2/search_folder_byid_admin_ignore'
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FOLDER + apiMoveFolder, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            // this.targetfolder = Folder ID ที่ต้องการย้าย
            if (response.data.folder.length > 0) {
              //กรณีที่ F
              if (response.data.your_folder[0]["folder_status"] === "R") {
                console.log("R&Citizen");
                this.moveto = response.data.your_folder[0]["folder_id"];
                this.parentdirectory = response.data.folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                this.enableback = false;
                this.parentname_biz =  this.business_name
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              } else {
                // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                this.moveto = response.data.your_folder[0]["folder_id"];
                this.parentdirectory = response.data.folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                this.enableback = false;
                this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                //! ของเดิมใช้ folder_name
                this.parentname_biz = response.data.your_folder[0]["folder_name"];
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              }
              let i;
              for (i = 0; i < response.data.folder.length; i++) {
                this.listbusiness.push(response.data.folder[i]);
              }
            } else {
              let user_id_biz = this.listbusiness.findIndex(
                (obj) =>
                  obj.folder_id === response.data.your_folder[0]["full_path_id"]
              );
              response.data.your_folder[0]["folder_status"] === "R" ?
                this.parentname_biz =  this.business_name : 
                this.parentname_biz = response.data.your_folder[0]["folder_name"];
              this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
              this.parentdirectory = response.data.your_folder[0]["account_id"];
              this.folderid = response.data.your_folder[0]["account_id"];
              this.account_active_inbiz =
                response.data.your_folder[0]["account_id"];
              this.full_path = response.data.your_folder[0]["full_path"];
              this.moveto = response.data.your_folder[0]["folder_id"];
            }
            this.item_ = "";
            this.clickerBusiness();
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
          }
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },

    async searchchildfolder_back(parameter2, account_active_inbizs) {
      this.createprogress = true;
      if (this.full_path === "Mainfolder") {
        this.fn_getlist();
        this.enableback = true;
      } else {
        this.listbusiness = [];
        this.targetfolder = "";
        let payload = {
          user_id: this.dataUsername,
          folder_id: parameter2,
          // account_id:this.dataAccountId,
          account_id: account_active_inbizs,
          status_folder: "",
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_SELECT_FOLDER +
              "/api/v2/search_folder_byid",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              // this.targetfolder = Folder ID ที่ต้องการย้าย
              console.log(response.data);
              if (response.data.folder.length > 0) {
                //กรณีที่ F
                if (response.data.your_folder[0]["folder_status"] === "R") {
                  console.log("R&Citizen");
                  this.moveto = response.data.your_folder[0]["folder_id"];
                  this.parentdirectory = response.data.your_folder[0]["account_id"];
                  this.enableback = false;
                  this.folderid = response.data.your_folder[0]["account_id"];

                  this.account_active_inbiz =
                    response.data.your_folder[0]["account_id"];
                  this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                  this.parentname_biz =  this.business_name
                  this.full_path = response.data.your_folder[0]["full_path"];
                } else {
                  // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                  this.moveto = response.data.your_folder[0]["folder_id"];
                  this.parentdirectory = response.data.your_folder[0]["account_id"];
                  this.folderid = response.data.your_folder[0]["account_id"];
                  this.enableback = false;
                  this.account_active_inbiz =
                    response.data.your_folder[0]["account_id"];
                  this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                  //! ของเดิมใช้ folder_name
                  this.parentname_biz =  response.data.your_folder[0]["folder_name"];
                  this.full_path = response.data.your_folder[0]["full_path"];
                }
                let i;
                for (i = 0; i < response.data.folder.length; i++) {
                  this.listbusiness.push(response.data.folder[i]);
                }
              } else {
                response.data.your_folder[0]["folder_status"] === "R" ?
                  this.parentname_biz =  this.business_name : 
                  this.parentname_biz = response.data.your_folder[0]["folder_name"];
                this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                this.parentdirectory = response.data.your_folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                console.log("this.folderid", this.folderid);
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              }
              this.createprogress = false;
            } else {
              this.listbusiness = [];
              this.createprogress = false;
              console.log("");
            }
          })
          .catch((err) => {
            this.listbusiness = [];
            this.createprogress = false;
            console.log(err);
          });
      }
    },
  },
  // watch: {
  //   targetfolder: function(val){
  //     this.parentfolder =
  //   }
  // }
};
</script>

<style></style>
